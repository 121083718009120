import React from "react";
import { TitleSection } from "../../components/TitleSection/TitleSectionComponent";

const WeofferSection = () => {
  return (
    <React.Fragment>
      <section id="weOffer" className="we-offer section-style">
        <TitleSection count="02." title="We offer" />

        <div className="width-container">
          <div className="we-offer-description-wrap">
            <hr className="custom-hr" />
            <p className="we-offer-description">
              Looking for the best software engineers despite the location? Want your entire team working in the same
              office? Pick the best option for you and we will cover the rest.
            </p>
          </div>

          <div className="remove-team">
            <h3 className="we-offer-title-section">Remote Team</h3>

            <ul className="we-offer-list">
              <li className="we-offer-list-item">Recruitment, Onboarding, Legal, Payroll, Taxes, Social benefits</li>
              <li className="we-offer-list-item">Working from home office or coworking spaces anywhere in Ukraine</li>
              <li className="we-offer-list-item">3-6 weeks to ramp-up a team of 5 engineers</li>
              <li className="we-offer-list-item">
                Engineers use their own hardware or we update it to something custom at your expense
              </li>
            </ul>
          </div>

          <hr className="custom-hr hr-space-top-and-bottom" />

          <div className="rd-office" id="rdOffice">
            <h3 className="we-offer-title-section">R&D Office</h3>

            <ul className="we-offer-list">
              <li className="we-offer-list-item">
                Recruitment, Onboarding, Legal, Payroll, Taxes, Social benefits,
                <strong>Travel management</strong>
              </li>
              <li className="we-offer-list-item">The whole team is based in one office in Kyiv, Ukraine</li>
              <li className="we-offer-list-item">4-8 weeks to ramp-up a team of 7-10 engineers</li>
              <li className="we-offer-list-item">Talent pool of 150.000+ IT specialists</li>
              <li className="we-offer-list-item">
                <strong>Office opening and operations management</strong>
              </li>
              <li className="we-offer-list-item">
                <strong>Support the relocation to Kyiv</strong>
              </li>
              <li className="we-offer-list-item">
                <strong>Free internal technical and product consultancy when needed</strong>
              </li>
              <li className="we-offer-list-item">
                <strong>Advanced ongoing HR support</strong>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <style jsx>{`
        .we-offer-description {
          font-family: Neutrif Pro;
          font-style: normal;
          font-weight: normal;
          line-height: 1.3;
          letter-spacing: -0.02em;
          color: #141418;
          margin-top: 30px;
        }
        .we-offer-title-section {
          font-family: Neutrif Pro;
          font-style: normal;
          font-weight: 500;
          line-height: 1.1;
          letter-spacing: -0.02em;
          color: #0005fb;
          flex-shrink: 0;
        }
        .we-offer-list {
          max-width: 550px;
        }
        .we-offer-list-item {
          padding-left: 30px;
          font-family: Neutrif Pro;
          font-style: normal;
          font-weight: normal;
          line-height: 1.4;
          color: #141418;
          position: relative;
        }
        .we-offer-list-item:before {
          content: "";
          height: 2px;
          width: 12px;
          background-color: #141418;
          position: absolute;
          left: 0;
          top: 12px;
        }

        @media screen and (min-width: 320px) {
          .we-offer-description {
            font-size: 24px;
          }
          .remove-team {
            margin-top: 40px;
          }
          .we-offer-title-section {
            font-size: 42px;
            margin-bottom: 18px;
          }
          .we-offer-list-item {
            font-size: 18px;
            margin-bottom: 12px;
          }
          .hr-space-top-and-bottom {
            margin: 20px 0;
          }
        }
        @media screen and (min-width: 1080px) {
          .we-offer-description {
            font-size: 36px;
            max-width: 570px;
          }
          .remove-team {
            margin-top: 70px;
            display: grid;
            grid-template-columns: 1fr 485px;
          }
          .we-offer-title-section {
            font-size: 74px;
            margin-right: 96px;
          }
          .we-offer-list {
            max-width: 550px;
          }
          .we-offer-list-item {
            font-size: 20px;
            margin-bottom: 20px;
          }
          .rd-office {
            display: grid;
            grid-template-columns: 1fr 485px;
          }
          .hr-space-top-and-bottom {
            margin: 50px 0;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

export { WeofferSection };
