import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import "./index.css";

const MainSection = () => {
  return (
    <React.Fragment>
      <section id="main" className="main">
        <h1 className="main-title">
          <span className="main-title-first-line">We build efficient</span>
          <span className="main-title-second-line">
            <span>Software</span>
            <span>&nbsp;</span>
            <AnchorLink className="main-link" to="/#weOffer">
              R<span className="mark-symbol">&</span>D offices
            </AnchorLink>
          </span>
          <span className="main-title-third-line">in Ukraine</span>
        </h1>
        <div className="scroll-indicator-wrapper">
          <span className="scroll-indicator">
            <span className="scroll-indicator-line" />
          </span>
          <span className="scroll-indicator-title">SCROLL DOWN</span>
        </div>
      </section>
    </React.Fragment>
  );
};

export { MainSection };
