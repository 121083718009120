/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { TitleSection } from "../../components/TitleSection/TitleSectionComponent";

const AboutSection = () => {
  return (
    <React.Fragment>
      <section id="aboutUs" className="about-us section-style">
        <TitleSection count="01." title="About us" />

        <div className="width-container">
          <hr className="custom-hr" />

          <div className="about-descriptions">
            <p className="description-first">
              We are <strong>techflower</strong> team. We help international businesses and startups launch remote
              software engineering teams and open efficient software R&D Offices in Ukraine.
            </p>

            <div className="descriptions">
              <p className="description">
                We're located in Kyiv, the capital of Ukraine, Europe's fastest-growing tech center.
              </p>
              <p className="description">
                Since 2018 we helped 15 clients from Europe, US and Singapore open their R&D offices in Kyiv which led
                to their huge transformation and results.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* --- STYLES --- */}
      <style jsx>{`
        .about-descriptions {
          margin-top: 30px;
        }
        .description-first {
          font-family: Neutrif Pro;
          font-style: normal;
          font-weight: normal;
          line-height: 1.3;
          letter-spacing: -0.02em;
          color: #141418;
        }
        .description {
          font-family: Neutrif Pro;
          font-style: normal;
          font-weight: normal;
          font-size: 22px;
          line-height: 1.4;
        }

        @media screen and (min-width: 320px) {
          .about-us {
            margin: 50px 0 50px;
          }
          .about-descriptions {
            display: block;
          }
          .description-first {
            margin-bottom: 25px;
            font-size: 28px;
          }
        }
        @media screen and (min-width: 1080px) {
          .about-us {
            margin: 180px 0 200px;
          }
          .about-descriptions {
            display: grid;
            grid-gap: 0 90px;
            justify-content: space-between;
            grid-template-columns: minmax(200px, 580px) minmax(150px, 430px);
          }
          .description-first {
            margin-bottom: 0;
            font-size: 36px;
          }
          .description:first-child {
            margin-bottom: 29px;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

export { AboutSection };
