import React from "react";
import "../../components/StyledButton/styleButton.css";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const HelpSection = () => {
  return (
    <React.Fragment>
      <section id="needHelp" className="need-help">
        <div className="need-help-width-container">
          <h2 className="need-help-title">
            Do you need our help <span className="painted-symbol">?</span>
          </h2>

          <AnchorLink stripHash className="lets-talk style-button" to="/#contactUs" title="Let’s talk">
            Let’s talk
          </AnchorLink>
        </div>
      </section>

      <style jsx>{`
        .need-help {
          display: flex;
          align-items: center;
          background: #f8f8f9;
        }
        .need-help-title {
          font-family: Neutrif Pro;
          font-style: normal;
          font-weight: 500;
          line-height: 1.1;
          letter-spacing: -0.02em;
          color: #141418;

          .painted-symbol {
            color: #0005fb;
          }
        }
        @media screen and (min-width: 320px) {
          .need-help-title {
            font-size: 32px;
            margin-bottom: 22px;
          }
          .need-help {
            min-height: 194px;
            margin-top: 50px;
            margin-bottom: 50px;
          }
          .need-help-width-container {
            padding: 0 10px;
            margin: auto;
          }
        }
        @media screen and (min-width: 1080px) {
          .need-help-title {
            font-size: 60px;
            margin-bottom: 40px;
          }
          .need-help {
            height: 336px;
            margin-top: 150px;
            margin-bottom: 150px;
            align-items: center;
          }
          .need-help-width-container {
            padding: 0 130px 0 220px;
            margin: 0;
          }
          :global(.lets-talk.style-button) {
            margin-left: 0;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

export { HelpSection };
