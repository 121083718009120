import React from "react";
import { BlogItem } from "./BlogItem";
import PropTypes from "prop-types";

const BlogSectionContent = ({ data }) => {
  const { posts } = data;

  const postsItems = () =>
    posts &&
    posts.edges &&
    posts.edges.map((item, key) => {
      return <BlogItem post={item.node} key={key} />;
    });

  return <ul className="blog-posts-wrap">{postsItems()}</ul>;
};

BlogSectionContent.propTypes = {
  data: PropTypes.object
};

export { BlogSectionContent };
