/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { TitleSection } from "../../components/TitleSection/TitleSectionComponent";

const StagesSection = () => {
  return (
    <React.Fragment>
      <section id="stages" className="stages section-style">
        <TitleSection count="04." title="stages" />
        <div className="width-container stages-desctions">
          <hr className="custom-hr" />

          <ol className="stages-list">
            <li className="stages-list-item">
              <h3 className="stages-list-title">Initiation</h3>
              <p className="stages-list-description">
                We start with your expectations and needs in software engineering. Our Recruitment Team will collaborate
                with you to draft the anticipated team profile: seniority levels, roles and responsibilities of every
                team member.
              </p>
            </li>
            <li className="stages-list-item">
              <h3 className="stages-list-title">Recruiting</h3>
              <p className="stages-list-description">
                This process will consist of several stages: pre-screens, technical assessment, technical interviews and
                calls with your representatives to get the final approval from your side. Once you are ready to lock the
                talent for your project, we'll come up with an official offer and handle all the paperwork internally.
              </p>
            </li>
            <li className="stages-list-item">
              <h3 className="stages-list-title">Onboarding</h3>
              <p className="stages-list-description">
                This is the time we make sure a newcomer has everything needed: a comfortable place if the office, all
                the equipment, required accounts, all legal docs are signed and put to the folder. Our HR manager will
                work closely with you to make sure we define your expectations and KPI on a team level.
              </p>
            </li>
            <li className="stages-list-item">
              <h3 className="stages-list-title">Operations and Support</h3>
              <p className="stages-list-description">
                Finally, we are here. You have a team that is ready to bring huge value to your business. We want to
                keep the collaboration simple but some things are important for us and we want to have them standing:
                regular HR check-in, quarterly business reviews and status meetings with you, online and offline
                team-buildings to make sure we're all engaged and connected.
              </p>
            </li>
          </ol>
        </div>
      </section>
      <style>{`
        .stages-list {
          list-style-type: decimal;
          font-family: Neutrif Pro;
          font-style: normal;
          font-weight: 500;
          line-height: 1.3;
          color: #141418;
          font-size: 28px;
        }
        .stages-list-item {
          padding-left: 12px;
        }
        .stages-list-item:not(:last-child) {
          margin-bottom: 40px;
        }
        .stages-list-title {
          font-family: Neutrif Pro;
          font-style: normal;
          font-weight: 600;
          line-height: 1.3;
          color: #141418;
          margin-bottom: 8px;
        }
        .stages-list-description {
          font-family: Neutrif Pro;
          font-style: normal;
          font-weight: normal;
          line-height: 1.4;
          color: #141418;
        }
        @media screen and (min-width: 320px) {
          .stages {
            margin: 50px 0;
          }
          .stages-list {
            margin-top: 25px;
            margin-left: 30px;
          }
          .stages-list-title {
            font-size: 24px;
          }
          .stages-list-description {
            font-size: 16px;
          }
        }
        @media screen and (min-width: 1080px) {
          .stages {
            margin: 200px 0;
          }
          .stages-list {
            margin-top: 50px;
            max-width: 860px;
          }
          .stages-list-title {
            font-size: 28px;
          }
          .stages-list-description {
            font-size: 20px;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

export { StagesSection };
