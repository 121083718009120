/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { TitleSection } from "../../components/TitleSection/TitleSectionComponent";

const WeCoverSection = () => {
  return (
    <React.Fragment>
      <section id="weCover" className="section-style">
        <TitleSection count="03." title="we cover" />
        <div className="width-container">
          <hr className="custom-hr" />

          <div className="we-cover-description-wrap">
            <div className="we-cover-item">
              <h3 className="we-cover-block-title">Recruitment</h3>
              <p className="we-cover-description">
                Our recruitment team will tackle a team of 7-10 engineers within 4-8 weeks timeframe.
              </p>
            </div>

            <div className="we-cover-item">
              <h3 className="we-cover-block-title">Office</h3>
              <p className="we-cover-description">
                Your team will be provided with the best well-equipped and dedicated working space
              </p>
            </div>

            <div className="we-cover-item">
              <h3 className="we-cover-block-title">Retention</h3>
              <p className="we-cover-description">
                Senior HR will make sure the entire team stays engaged and collaboration is productive.
              </p>
            </div>

            <div className="we-cover-item">
              <h3 className="we-cover-block-title">Travel management</h3>
              <p className="we-cover-description">
                We'll be happy to arrange visits between you and your team as often as you need
              </p>
            </div>

            <div className="we-cover-item">
              <h3 className="we-cover-block-title">Payroll</h3>
              <p className="we-cover-description">
                The finance department will handle all payments to your team, taxes management etc.
              </p>
            </div>

            <div className="we-cover-item">
              <h3 className="we-cover-block-title">Legal</h3>
              <p className="we-cover-description">
                Our legal counsel will make sure your IP is properly transferred and your interests are protected.
              </p>
            </div>
          </div>
        </div>
      </section>
      <style jsx>{`
        .we-cover-block-title {
          font-family: Neutrif Pro;
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 1.3;
          color: #0005fb;
          margin-bottom: 8px;
        }

        .we-cover-description {
          font-family: Neutrif Pro;
          font-style: normal;
          font-weight: normal;
          line-height: 1.4;
          color: #141418;
        }

        @media screen and (min-width: 320px) {
          .we-cover-description-wrap {
            margin-top: 32px;
          }
          .we-cover-item {
            margin-bottom: 25px;
          }
          .we-cover-description {
            font-size: 16px;
          }
        }
        @media screen and (min-width: 1080px) {
          .we-cover-description-wrap {
            margin-top: 50px;
            display: grid;
            grid-gap: 50px 90px;
            grid-template-columns: repeat(2, minmax(300px, 460px));
            justify-content: space-between;
          }
          .we-cover-item {
            margin-bottom: 0;
          }
          .we-cover-description {
            font-size: 20px;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

export { WeCoverSection };
