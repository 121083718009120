import React from "react";
import { BlogSection } from "../sections/BlogSection/BlogSection";
import { MainSection } from "../sections/MainSection/Main";
import { AboutSection } from "../sections/AboutSection/About";
import { WeofferSection } from "../sections/WeofferSection/Weoffer";
import { HelpSection } from "../sections/HelpSection/Help";
import { WeCoverSection } from "../sections/WeCoverSection/WeCover";
import { StagesSection } from "../sections/StagesSection/Stages";
import { ContactSection } from "../sections/ContactSection/ContactSection";

const IndexPage = () => {
  return (
    <React.Fragment>
      <MainSection />
      <AboutSection />
      <WeofferSection />
      <HelpSection />
      <WeCoverSection />
      <StagesSection />
      <BlogSection />
      <ContactSection />
    </React.Fragment>
  );
};

export default IndexPage;
