import React from "react";
import { Link } from "gatsby";
import { BlogSectionContent } from "./BlogSectionContent";
import { TitleSection } from "../../components/TitleSection/TitleSectionComponent";
import { useStaticQuery, graphql } from "gatsby";

import "../../components/StyledButton/styleButton.css";

const BlogSection = () => {
  const data = useStaticQuery(graphql`
    query BlogSectionQuery {
      posts: allMarkdownRemark(
        limit: 4
        filter: { fileAbsolutePath: { regex: "//posts/" } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            excerpt
            fields {
              slug
            }
            frontmatter {
              title
              date(formatString: "MMMM DD YYYY")
              cover {
                childImageSharp {
                  fluid(maxWidth: 295, maxHeight: 180) {
                    src
                    srcSet
                    aspectRatio
                    sizes
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <React.Fragment>
      <section id="blog" className="blog-section section-style">
        <TitleSection count="05." title="blog" />
        <div className="width-container">
          <hr className="custom-hr" />
          <p className="blog-short-description">
            From deep technical topics to current business trends our articles have you covered.
          </p>
          <BlogSectionContent data={data} />
          <div className="blog-link-container">
            <Link to="/blog" className="view-all-posts style-button">
              View all
            </Link>
          </div>
        </div>
      </section>

      {/* --- STYLES --- */}
      <style jsx>{`
        .blog-short-description {
          font-family: Neutrif Pro;
          font-style: normal;
          font-weight: normal;
          line-height: 1.3;
          color: #141418;
          letter-spacing: -0.02em;
        }
        @media screen and (min-width: 320px) {
          .blog-short-description {
            font-size: 24px;
            margin: 25px 0 50px;
          }
          .blog-link-container {
            margin: 15px auto 0;
          }
        }
        @media screen and (min-width: 1080px) {
          .blog-short-description {
            font-size: 36px;
            max-width: 550px;
            margin: 30px 0 70px;
          }
          .blog-link-container {
            margin-top: 60px;
          }
          .blog-section {
            margin-top: 200px;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

export { BlogSection };
